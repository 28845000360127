import { createI18nMessage } from '@vuelidate/validators';
import * as validators from '@vuelidate/validators';

export default () => {
  const i18n = useI18n();
  const withI18nMessage = createI18nMessage({
    t: i18n.t.bind(i18n),
  });

  const required = withI18nMessage(validators.required, {
    withArguments: true,
  });

  const minLength = withI18nMessage(validators.minLength, {
    withArguments: true,
  });

  const minValue = withI18nMessage(validators.minValue, {
    withArguments: true,
  });

  const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });

  const url = withI18nMessage(validators.url);
  const email = withI18nMessage(validators.email);

  const fileTypeValidation = withI18nMessage(
    (file: File) => {
      if (!file) {
        return true;
      }
      return ['image/jpeg', 'image/png'].includes(file.type);
    },
    { messagePath: () => 'validations.picture.type' },
  );

  const fileSizeValidation = withI18nMessage(
    (file: File) => {
      if (!file) {
        return true;
      }
      const MAX_FILE_SIZE_IN_MB = 5;
      const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * Math.pow(2, 20); // 1 MB = 2^20 Bytes in base 2

      return file.size < MAX_FILE_SIZE_IN_BYTES;
    },
    {
      messagePath: () => 'validations.picture.size',
      messageParams(params) {
        return {
          ...params,
          maxSize: '5MB',
        };
      },
    },
  );

  return {
    required,
    minLength,
    minValue,
    url,
    email,
    fileSizeValidation,
    fileTypeValidation,
    sameAs,
  };
};
