import useVuelidate from '@vuelidate/core';
import type { UnwrapNestedRefs } from 'vue';

export default function useValidation() {
  const i18nValidators = useI18nValidators();

  function setValidation(
    src: UnwrapNestedRefs<Record<string, unknown>>,
    rules: any,
  ) {
    return useVuelidate(rules, src);
  }
  return {
    setValidation,
    ...i18nValidators,
  };
}
